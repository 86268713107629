<template>
  <div class="workspace__container__view">
    <div class="workspace__container__view__header">
      <div class="workspace__container__view__header__actions">
        <button @click="createOrEdit()">{{ $t("workspace.spot.create_spot") }}</button>
      </div>
      <div class="workspace__container__view__header__filter">
        <div class="container__inputs">
          <div class="settings-input">
            <select v-model="filterModel.stationName">
              <option value="">{{$t('workspace.ad_agency.filter_for')}} {{$t('workspace.spot.station')}}</option>
              <option v-for="station in listStations" :value="station.name" :key="station.id">{{station.name}}</option>
            </select>
          </div>
          <div class="settings-input">
            <select v-model="filterModel.adAgencyName">
              <option value="">{{$t('workspace.ad_agency.filter_for')}} {{$t('workspace.spot.ad_agency')}}</option>
              <option v-for="agency in listAgencies" :value="agency.name" :key="agency.id">{{agency.code}} - {{agency.name}}</option>
            </select>
          </div>
          <div class="settings-input">
            <select v-model="filterModel.customerName">
              <option value="">{{$t('workspace.ad_agency.filter_for')}} {{$t('workspace.spot.customer')}}</option>
              <option v-for="customer in listClients" :value="customer.name" :key="customer.id">{{customer.code}} - {{customer.name}}</option>
            </select>
          </div>
          <div class="settings-input">
            <input type="text" v-model="filterModel.spotName" :placeholder="$t('workspace.client.filter_for') + $t('workspace.spot.spot')">
          </div>
          <div class="settings-input">
            <input type="text" v-model="filterModel.internalNumber" :placeholder="$t('workspace.client.filter_for') + $t('workspace.spot.internal_number')">
          </div>
          <div class="settings-input">
            <input type="text" v-model="filterModel.agencyOrderNumber" :placeholder="$t('workspace.client.filter_for') + $t('workspace.spot.agency_number')">
          </div>
        </div>
        <button 
          :disabled="filterModel.stationName == '' && filterModel.adAgencyName == '' && filterModel.customerName == '' && filterModel.spotName == '' && filterModel.internalNumber == '' && filterModel.agencyOrderNumber == ''"
          @click="filterModel.stationName = ''; filterModel.adAgencyName = ''; filterModel.customerName = '';  filterModel.spotName = '';  filterModel.internalNumber = '';  filterModel.agencyOrderNumber = '';">
            {{ $t("workspace.clean_filter") }}
        </button>
      </div>
    </div>

    <table class="workspace__container__view__table">
      <thead>
        <tr>
          <th>{{ $t("workspace.spot.station") }}</th>
          <th>{{ $t("workspace.spot.ad_agency") }}</th>
          <th>{{ $t("workspace.spot.customer") }}</th>
          <th>{{ $t("workspace.spot.spot") }}</th>
          <th>{{ $t("workspace.spot.internal_number") }}</th>
          <th>{{ $t("workspace.spot.agency_number") }}</th>
          <th class="actions">{{ $t("workspace.spot.actions") }}</th>
        </tr>
      </thead>
      <tbody>
        <template v-if="listSpots(filterModel).length > 0">  
          <tr v-for="item in listSpots(filterModel)" :key="item.code">
            <td v-html="highlightText(item.stationName, filterModel.stationName)"></td>
            <td v-html="highlightText(item.adAgencyName, filterModel.adAgencyName)"></td>
            <td v-html="highlightText(item.customerName, filterModel.customerName)"></td>
            <td v-html="highlightText(item.spotName, filterModel.spotName)"></td>
            <td v-html="highlightText(item.internalNumber, filterModel.internalNumber)"></td>
            <td v-html="highlightText(item.agencyOrderNumber, filterModel.agencyOrderNumber)"></td>
            <td class="actions">
              <i class="material-icons" @click="createOrEdit(item.id)">edit</i>
              <i class="material-icons" @click="deleteItem(item)">delete</i>
            </td>
          </tr>
        </template>
        <tr v-if="listSpots(filterModel).length == 0 && !isFilterActive">
          <td colspan="7">{{$t('workspace.not_have_loaded')}} <strong>{{ $t("left_menu.spot_programming.spot") }}</strong>. {{$t('workspace.please')}} <strong style="text-decoration: underline; cursor: pointer" @click="createOrEdit()">{{ $t("workspace.spot.create_spot") }}</strong></td>
        </tr>
        <tr v-if="listSpots(filterModel).length == 0 && isFilterActive">
          <td colspan="7">{{$t('workspace.none_results_with_filters')}}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import { useStore } from 'vuex'
import { computed, onMounted } from '@vue/runtime-core';
import highlightText from '@/plugins/Mixin/highlightText.js';
import { useRouter } from 'vue-router';

export default {
  mixins: [highlightText],
  setup() {
    const store = useStore();
    const router = useRouter();
    const listSpots = computed(() => filterModel => store.getters.spots_spots(filterModel));
    const listStations = computed(() => store.getters.station_stations({name: ''}));
    const listAgencies = computed(() => store.getters.adAgency_agencies({name: '', code: '', adAgencyGroupId: 0, taxCode: ''}));
    const listClients = computed(() => store.getters.client_clients({name: '', code: '', taxCode: ''}));
    const isFilterActive = computed(() => filterModel.value.stationName != '' || filterModel.value.adAgencyName != '' || filterModel.value.customerName != '' || filterModel.value.spotName != '' || filterModel.value.internalNumber != '' || filterModel.value.agencyOrderNumber != '');
    const filterModel = ref({
      stationName: '',
      adAgencyName: '',
      customerName: '',
      spotName: '',
      internalNumber: '',
      agencyOrderNumber: ''
    })

    onMounted(async () => {
      store.dispatch('loading_setActive', true);
      await store.dispatch('spots_getAll');
      await store.dispatch('station_getAll');
      await store.dispatch('adAgency_getAll');
      await store.dispatch('client_getAll');
      store.dispatch('loading_setActive', false);
    });

    async function createOrEdit(id = `create${new Date().getUTCMilliseconds()}`) {
      const tab = {
        id,
        module: 'spot_programming',
        name: 'spot/detail',
        extendedData: {}
      };
      await store.dispatch('tabs_addTab', tab);
      await store.dispatch('tabs_setActive', tab);
      router.push(`/${tab.module}/${tab.name}/${tab.id}`);
    }
    
    async function deleteItem(item) {
      await store.dispatch('modal_setActive', {active: 'COMMON_DELETE', props: item, actionDelete: 'spots_delete'});
    }

    return {
      listSpots,
      listStations,
      listAgencies,
      listClients,
      filterModel,
      isFilterActive,
      createOrEdit,
      deleteItem
    }
  }
}
</script>
<style lang="">
  
</style>